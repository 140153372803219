import React, { useState } from "react";
import { Button, FormFeedback, Input } from "reactstrap";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";
import logo from './images/Statetransport.JPG';
import { BASEAPI } from "../contexts/constants";
import { handleErrors } from "./Helpers";
import "./Login.css";
import { PostJson } from "./fetch_helpers";

export default function Login()
{
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

    async function handleSubmit(e) {
        e.preventDefault();
        
        if (username === undefined)
        {
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging In", errorMessage: "Please enter username"});
            e.preventDefault();
            return false;
        }

        if (password === undefined)
        {
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging In", errorMessage: "Please enter password"});
            e.preventDefault();
            return false;
        }

        try
        {
            var result = await loginUser({username, password})
            var token = result.token;
            var user = result.user;

            console.log("token = " + token);
            dispatchUser({type: "SET_TOKEN", token: token});

            console.log("user = " + JSON.stringify(user));
            dispatchUser({type: "INIT", user: user.userName, userId: user.userId, canRoute: result.canRoute, externalLogin: true, userDepots: user.depots});
        }
        catch (err)
        {
             console.log(err);
        }

        return true;
    }

    async function loginUser(credentials)
    {
        const uri = BASEAPI + "/api/account/signin"
        console.log('fetching: ' + uri)

        var args = {
            username: credentials.username,
            password: credentials.password
        };

        var result = await PostJson(uri, args)
        .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging In", errorMessage: error.message});
        })

        return result;
    }

    return (
        <>
            <div className="logo-wrapper">
                <img src={logo} className="logo" /> State Transport Delivery Router
            </div>
            <div className="login-wrapper">
                <h3>Please Log In</h3>
                <form onSubmit={handleSubmit} inline>
                    <label>
                        <p>Username</p>
                        <Input type="text" className="login-field" onChange={e => setUserName(e.target.value)} invalid={username && username.length <= 0} />
                        <FormFeedback valid={!username || username.length == 0}>Please user email address</FormFeedback>
                    </label>
                    <label>
                        <p>Password</p>
                        <Input type="password" className="login-field" onChange={e => setPassword(e.target.value)} invalid={password && password.length <= 0} />
                        <FormFeedback valid={!password || password.length == 0}>Please enter password</FormFeedback>
                    </label>
                    <div>
                        <Button>Login</Button>
                    </div>
                </form>
            </div>
           
        </>
    )
}